import React, { useEffect } from 'react'
import { Link, navigate, useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import SEO from '../../components/seo'
import PressRelease from '../../components/PressRelease'

import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import Image from '../../images/news/legacy-teaser.jpg'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    if (global.window && locale === 'en') {
      navigate('/newsroom/')
    }
  }, [])

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsLegacy)}
        imageAlt={formatMessage(messages.TitleNewsLegacy)}
        description={formatMessage(metaDescription.NewsLegacyDescription)}
        image={Image}
        nofollow={locale === 'en'}
        lang={locale}
      />
      <PressRelease
        article={newsList.legacy}
        articleLinks={{
          modernization: <Link to='/solutions/it-system-modernization/'>
            System-Modernisierung
          </Link>,
          youtube: <a href="https://youtu.be/kYcf4ESUcm8?t=1841" target='_blank' rel='noopener noreferrer'>
            Youtube
          </a>
        }}
      />
    </Layout>
  )
}

export default IndexPage
